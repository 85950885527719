import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import "./confirmation-record.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import { resetRegistration } from "../redux/registration/registrationAction";

const RecordRegistrationPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetRegistration())
        return () => {
            dispatch(resetRegistration())
        }
    }, [])

    return (
        <Layout main={false}>
            <SEO title="CFP 2022 confirmation d'inscription" />
            <Header />
            <div className={"page"}>
                <h2 className={"page-title"}>Votre inscription a bien été sauvegardée</h2>

                <p>
                Vous recevrez bientôt une confirmation d'inscription par mail contenant votre facture proforma. Pensez à vérifier votre dossier de courrier indésirable.<br />Le code barre pour retirer votre badge à Lille et le lien vers la plateforme virtuelle seront envoyés quelques jours avant l'ouverture du congrès.
</p>
                <strong>CONGRÈS FRANÇAIS DE PSYCHIATRIE 2022</strong>
            </div>
            <div className={"content-footer footer-alone"}>
                <a href="https://cfp2022.urevent.fr/login" className={"sr-btn-next btn btn-info"} >Connectez-vous</a>


            </div>

        </Layout>
    )
}

export default RecordRegistrationPage
